// mixin.js

export default {
    data () {
        return {
            DYNAMIC_URL: 'http://api.jmsc.xyz/',
            BASE_URL: '',
        }
    },
    created () {

    },
    methods: {

    }
}

